import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { companiesActions as actions, companiesActions } from '../../../actions';
import { companyStatuses, companyColumns as columns, routes, companyRoles, constants } from '../../../constants';
import { getActiveMembers, getCompanies } from '../../../selectors';
import { Checkbox, ContextMenu, Popup } from '../../controls';
import { history } from '../../../history';
import { EmptyPlaceholder, OnHoverTooltip, Relative, Preloader } from '../../common';
import { RequestState } from '../../../constants/request-state';
import { ConfirmationPopup } from './ConfirmationPopup';
import { CompanyStatus } from './CompanyStatus';
import classNames from 'classnames';
import { UserProfilePhotoViewer } from '../../user-profile-photo/UserProfilePhotoViewer';
import { nameUtils } from '../../../utils';
import { SetClearingBankPopup } from './clearing-bank/SetClearingBankPopup';
import { ListBuilder } from '../../../utils/ListBuilder';
import IconSVG from "../../../styles/svg-icons";
import { ViewSettlementAgentCommission } from '../../clients/companies/ViewSettlementAgentCommission';
import { Link } from 'react-router-dom';
import { changeSubscriptionActions } from '../../../actions/change-subscription.actions';
import { ChangeSubscriptionPopup } from './change-subscription/ChangeSubscriptionPopup';

class CompanyList extends Component {
    state = {
        clearingBankCompany: null
    };

    handleClearingBankSave = () => {
        this.setState({ clearingBankCompany: null });
    };

    renderOfacField = company => {
        const { dispatch, companiesOfacRequestStatus } = this.props;
        return (
            <Preloader inProgress={companiesOfacRequestStatus[company.id]} fullScreen={false} small={true}>
                <Checkbox
                    checked={company.ofac || false}
                    onChange={(e) => dispatch(actions.setCompanyOfacStatus(company.id, e.target.checked))}
                />
            </Preloader>
        )
    };

    renderSalesRepresentative = representative => {
        return (
            <Relative key={representative.id} inline>
                <OnHoverTooltip overlay={`${representative.firstName} ${representative.lastName}`}>
                    <span>
                        <UserProfilePhotoViewer userId={representative.id} alt={nameUtils.getInitials(representative.firstName, representative.lastName)} thumbnail={true} />
                    </span>
                </OnHoverTooltip>
            </Relative>
        );
    };

    handleResetFilterAndSearch = () => {
        const { dispatch, handleSearchTermChange } = this.props;
        dispatch(companiesActions.resetCompanyFilters());
        handleSearchTermChange('');
    }

    render = () => {
        const {
            isInitCompleted, companies, dispatch, statusChangeConfirmation,
            approvalConfirmation, rejectionConfirmation
        } = this.props;

        if (isInitCompleted && !companies.length) {
            return <EmptyPlaceholder onResetClick={this.handleResetFilterAndSearch}/>;
        }

        const companyRows = companies.map(c =>
            <tr
                key={c.id}
                className={classNames(
                    { 'highlighted': statusChangeConfirmation && statusChangeConfirmation.company && statusChangeConfirmation.company.id === c.id }
                )}
            >
                <td><CompanyStatus company={c} /></td>
                <td>{c.rolesText}</td>
                <td>
                    <OnHoverTooltip overlay={c.name}>
                        <span className="text-ellipsis">{c.name}</span>
                    </OnHoverTooltip>
                </td>
                <td>
                    <OnHoverTooltip overlay={this.getPrimaryContactName(c.primaryContact)}>
                        <span className="text-ellipsis">{this.getPrimaryContactName(c.primaryContact) || '–'}</span>
                    </OnHoverTooltip>
                </td>
                <td>
                    {c.members ? <button
                        className="btn btn-counter"
                        onClick={() => dispatch(actions.showCompanyMembers(c))}>
                        {c.members}
                    </button> : <Link className="btn-link" to={{ pathname: routes.manageCompaniesCreateUser, state: { companyId: c.id } }}>add</Link>}
                </td>
                <td>
                    <div className="sales-reps flex-row">
                        {
                            c.salesRepresentatives &&
                            !!c.salesRepresentatives.length &&
                            c.salesRepresentatives
                                .filter(s => s.active)
                                .map(s => this.renderSalesRepresentative(s))
                        }
                    </div>
                </td>
                <td>{c.subscriptionText ?? constants.emptyPlaceholder}</td>
                <td>{c.subscriptionDate ? moment(c.subscriptionDate).format(constants.dateFormat4) :constants.emptyPlaceholder}</td>
                <td>{moment(c.dateRegistered).format('MM/DD/YY')}</td>
                <td>
                    <OnHoverTooltip overlay={this.getCreatedBy(c.createdBy)}>
                        <span className="text-ellipsis">{this.getCreatedBy(c.createdBy)}</span>
                    </OnHoverTooltip>
                </td>
                <td className="text-right">{this.renderOfacField(c)}</td>
                <td>{this.renderClearingBankColumn(c)}</td>
                <td className="text-right">
                    {
                        !this.isRequestingApproval(c.id) ?
                            <ContextMenu menuItems={this.getMenuItems(c)} /> : 'Requesting...'
                    }
                </td>
            </tr >
        );

        return (
            <div className="container">
                <Preloader inProgress={!isInitCompleted}>
                    <div className="container companies-table">
                        <div className="table-head">
                            <table className="main-table">
                                <colgroup>
                                    <col width='9%' />
                                    <col width='10%' />
                                    <col width='11%' />
                                    <col width='10%' />
                                    <col width='5%' />
                                    <col width='8%' />
                                    <col width='9%' />
                                    <col width='8%' />
                                    <col width='7%' />
                                    <col width='9%' />
                                    <col width='4%' />
                                    <col width='4%' />
                                    <col width='6%' />
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th className={`cell-sort ${this.getOrderByCssClasses(columns.status)}`}
                                            onClick={() => dispatch(actions.orderCompaniesBy(columns.status))}>
                                            Status
                                            <IconSVG name="corner-arrow" className="icon-cell-sort" width={16} height={16} />
                                        </th>
                                        <th className={`cell-sort ${this.getOrderByCssClasses(columns.role)}`}
                                            onClick={() => dispatch(actions.orderCompaniesBy(columns.role))}>
                                            Role
                                            <IconSVG name="corner-arrow" className="icon-cell-sort" width={16} height={16} />
                                        </th>
                                        <th className={`cell-sort ${this.getOrderByCssClasses(columns.legalName)}`}
                                            onClick={() => dispatch(actions.orderCompaniesBy(columns.legalName))}>
                                            Company Legal Name
                                            <IconSVG name="corner-arrow" className="icon-cell-sort" width={16} height={16} />
                                        </th>
                                        <th className={`cell-sort ${this.getOrderByCssClasses(columns.primaryContact)}`}
                                            onClick={() => dispatch(actions.orderCompaniesBy(columns.primaryContact))}>
                                            Primary Contact
                                            <IconSVG name="corner-arrow" className="icon-cell-sort" width={16} height={16} />
                                        </th>
                                        <th className={`cell-sort ${this.getOrderByCssClasses(columns.members)}`}
                                            onClick={() => dispatch(actions.orderCompaniesBy(columns.members))}>
                                            Users
                                            <IconSVG name="corner-arrow" className="icon-cell-sort" width={16} height={16} />
                                        </th>
                                        <th>Sales Reps</th>
                                        <th
                                            className={`cell-sort ${this.getOrderByCssClasses(columns.subscription)}`}
                                            onClick={() => dispatch(actions.orderCompaniesBy(columns.subscription))}
                                        >
                                            Subs. Plan
                                            <IconSVG name="corner-arrow" className="icon-cell-sort" width={16} height={16} />
                                        </th>
                                        <th
                                            className={`cell-sort ${this.getOrderByCssClasses(columns.subscriptionEnd)}`}
                                            onClick={() => dispatch(actions.orderCompaniesBy(columns.subscriptionEnd))}
                                        >
                                            Subs. End
                                            <IconSVG name="corner-arrow" className="icon-cell-sort" width={16} height={16} />
                                        </th>
                                        <th className={`cell-sort ${this.getOrderByCssClasses(columns.dateRegistered)}`}
                                            onClick={() => dispatch(actions.orderCompaniesBy(columns.dateRegistered))}>
                                            Reg. Date
                                            <IconSVG name="corner-arrow" className="icon-cell-sort" width={16} height={16} />
                                        </th>
                                        <th className={`cell-sort ${this.getOrderByCssClasses(columns.createdBy)}`}
                                            onClick={() => dispatch(actions.orderCompaniesBy(columns.createdBy))}>
                                            Reg. by
                                            <IconSVG name="corner-arrow" className="icon-cell-sort" width={16} height={16} />
                                        </th>
                                        <th className="text-right">
                                            OFAC
                                        </th>
                                        <th className={`cell-sort ${this.getOrderByCssClasses(columns.isClearingBank)}`}
                                            onClick={() => dispatch(actions.orderCompaniesBy(columns.isClearingBank))}>
                                            <OnHoverTooltip overlay="Settlement Agent">
                                                <span>SA</span>
                                            </OnHoverTooltip>
                                            <IconSVG name="corner-arrow" className="icon-cell-sort" width={16} height={16} />
                                        </th>
                                        <th className="text-right">
                                            Actions
                                        </th>
                                    </tr>
                                </thead>
                            </table>
                        </div>
                        <div className="manage-list container-flex">
                            <table className="main-table">
                                <colgroup>
                                    <col width='9%' />
                                    <col width='10%' />
                                    <col width='11%' />
                                    <col width='10%' />
                                    <col width='5%' />
                                    <col width='8%' />
                                    <col width='9%' />
                                    <col width='8%' />
                                    <col width='7%' />
                                    <col width='9%' />
                                    <col width='4%' />
                                    <col width='4%' />
                                    <col width='6%' />
                                </colgroup>
                                <tbody>
                                    {companyRows}
                                </tbody>
                            </table>
                        </div>
                        {
                            statusChangeConfirmation &&
                            statusChangeConfirmation.company &&
                            <ConfirmationPopup statusChangeConfirmation={statusChangeConfirmation} />
                        }
                        {
                            approvalConfirmation &&
                            <Popup
                                title="Confirmation"
                                modealClass="modal-delete"
                                onClose={() => dispatch(actions.companyApprovalConfirmationHide())}
                            >
                                <div className="modal-body">
                                    <p>
                                        Please confirm you approve {approvalConfirmation.name} to join the KTX ATS Platform
                                    </p>
                                </div>
                                <div className="modal-footer">
                                    <button
                                        className="btn btn-ghost"
                                        onClick={() => dispatch(actions.companyApprovalConfirmationHide())}
                                        disabled={this.isRequestingApproval(approvalConfirmation.id)}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        className="btn btn-main"
                                        onClick={() => dispatch(actions.approveCompany(approvalConfirmation.id))}
                                        disabled={this.isRequestingApproval(approvalConfirmation.id)}
                                    >
                                        {this.isRequestingApproval(approvalConfirmation.id) ? 'Approving' : 'Yes'}
                                    </button>
                                </div>
                            </Popup>
                        }
                        {
                            rejectionConfirmation &&
                            <Popup
                                title="Confirmation"
                                modalClass="modal-delete"
                                onClose={() => dispatch(actions.companyRejectionConfirmationHide())}
                            >
                                <div className="modal-body">
                                    <p>
                                        Please enter the reason of rejection and confirm
                                    </p>
                                    <div className="form-row">
                                        <label className="form-label" htmlFor="rejectionReason">Rejection reason <span
                                            className="text-red">*</span></label>
                                        <textarea
                                            required
                                            id="rejectionReason"
                                            className="form-control"
                                            maxLength="255"
                                            value={this.props.rejectionReason}
                                            onChange={e => dispatch(actions.companyRejectReasonSet(e.target.value))}
                                        />
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button
                                        className="btn btn-ghost"
                                        onClick={() => dispatch(actions.companyRejectionConfirmationHide())}
                                        disabled={this.isRequestingApproval(rejectionConfirmation.id)}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        className="btn btn-main btn-danger"
                                        onClick={() => dispatch(actions.rejectCompany(rejectionConfirmation.id))}
                                        disabled={this.isRequestingApproval(rejectionConfirmation.id) || !this.props.rejectionReason.trim()}
                                    >
                                        {this.isRequestingApproval(rejectionConfirmation.id) ? 'Rejecting' : 'Reject'}
                                    </button>
                                </div>
                            </Popup>
                        }
                        {
                            this.state.clearingBankCompany &&
                            <SetClearingBankPopup
                                company={this.state.clearingBankCompany}
                                onSave={this.handleClearingBankSave}
                                onCancel={() => this.setState({ clearingBankCompany: null })}
                            />
                        }
                        {
                            this.props.isChangeSubscriptionPopupVisible &&
                            <ChangeSubscriptionPopup activeUsers={this.props.activeUsers} />
                        }
                    </div>
                </Preloader>
            </div>
        );
    }

    getOrderByCssClasses = column =>
        this.props.orderBy.column === column
            ? this.props.orderBy.direction
            : '';

    getStatusChangeConfirmationMessage = company => {
        const status =
            company.status === companyStatuses.active.statusCode ||
                company.status === companyStatuses.offPlatform.statusCode
                ? 'block' : 'unblock';
        return `Do you really want to ${status} ${company.name}?`;
    }

    getPrimaryContactName = contact => contact ? `${contact.firstName} ${contact.lastName}` : '';
    getCreatedBy = createdBy => createdBy ? `${createdBy}` : '–';

    getMenuItems = company => {
        switch (company.status) {
            case companyStatuses.offPlatform.statusCode:
            case companyStatuses.active.statusCode:
                return this.getMenuActive(company);
            case companyStatuses.blocked.statusCode:
                return this.getMenuBlocked(company);
            case companyStatuses.registrationPending.statusCode:
                return this.getMenuPending(company);
            case companyStatuses.registrationSubmitted.statusCode:
                return this.getMenuSubmitted(company);
            case companyStatuses.registrationRejected.statusCode:
                return this.getMenuRejected();
            default:
                return [];
        }
    }

    getMenuActive = company => {
        const edit = {
            text: 'Edit',
            action: () => history.push(routes.editCompanyUrl(company.referenceName))
        };
        const clearingBank = {
            text: company.isClearingBank ? 'Unset Settl. Agent and Commission' : 'Set Settl. Agent and Commission',
            action: () => this.setState({ clearingBankCompany: company })
        };
        const block = {
            classNames: 'warning',
            text: 'Block',
            action: () => this.props.dispatch(actions.companyStatusChangeConfirmation(true, company))
        };
        const subscription = {
            text: 'Change Subscription',
            action: () => this.props.dispatch(changeSubscriptionActions.show(company))
        }

        return new ListBuilder()
            .add(edit)
            .addWhen(() =>
                (company.status === companyStatuses.active.statusCode || this.props.activeUsers.some(u => u.companyId === company.id)) &&
                company.role !== companyRoles.Administrator,
                subscription)
            .addWhen(() => company.role === companyRoles.BrokerDealer, clearingBank)
            .add(block)
            .result();
    };

    getMenuBlocked = company => [
        {
            text: 'Edit',
            action: () => history.push(routes.editCompanyUrl(company.referenceName))
        },
        {
            text: 'Unblock',
            action: () => this.props.dispatch(actions.companyStatusChangeConfirmation(true, company))
        }
    ]

    getMenuPending = company => [
        {
            text: 'Edit',
            action: () => history.push(routes.editCompanyUrl(company.referenceName))
        }
    ]

    getMenuSubmitted = company => [
        {
            text: 'Edit',
            action: () => history.push(routes.editCompanyUrl(company.referenceName))
        },
        {
            text: 'Approve',
            action: () => this.props.dispatch(actions.companyApprovalConfirmationShow(company))
        },
        {
            text: 'Reject',
            action: () => this.props.dispatch(actions.companyRejectionConfirmationShow(company))
        }
    ]

    getMenuRejected = () => []

    isRequestingApproval = companyId => {
        const { rsStatusChange } = this.props;
        return rsStatusChange[companyId] && rsStatusChange[companyId] === RequestState.request;
    }

    renderClearingBankColumn = company => {
        if (company.role === companyRoles.BrokerDealer) {
            return company.isClearingBank
                ? <ViewSettlementAgentCommission
                    text="Yes"
                    buttonIconVisible={false}
                    companyId={company.id}
                    companyName={company.name}
                />
                : 'No';
        } else {
            return constants.emptyPlaceholder;
        }
    }
}

const mapStateToProps = ({ companies, changeSubscription }) => ({
    isInitCompleted: companies.isInitCompleted,
    companies: getCompanies(companies),
    activeUsers: getActiveMembers(companies),
    approvalConfirmation: companies.approvalConfirmation,
    rejectionConfirmation: companies.rejectionConfirmation,
    statusChangeConfirmation: companies.companyStatusChangeConfirmation,
    rsStatusChange: companies.rsStatusChange,
    rejectionReason: companies.rejectionReason,
    filter: companies.companyFilter,
    orderBy: companies.companyOrderBy,
    companiesOfacRequestStatus: companies.companiesOfacRequestStatus,
    isChangeSubscriptionPopupVisible: changeSubscription.popupVisible,
});

const connectedCompanyList = connect(mapStateToProps)(CompanyList);
export { connectedCompanyList as CompanyList };

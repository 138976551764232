import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from "formik";
import { Checkbox, Popup, PopupBody, PopupFooter } from '../../controls';
import { AppState } from '../../../types/state/AppState';
import { manageTokenActions } from '../../../actions/manage-tokens.actions';
import { AccessTokenApi, AccessTokenApiLabels, AccessToken } from '../../../types/management/AccessToken';
import { StatusMessageSection } from '../../status-message/StatusMessageSection';
import { StatusMessageSectionType } from '../../../types/state/NotificationState';
import { RequestState } from '../../../constants/request-state';
import { CopyToClipboard, OnHoverTooltip } from '../../common';
import { isRequesting, isRequestSuccess } from '../../../utils';
import IconSVG from '../../../styles/svg-icons';
import { InfoTooltip } from '../../common/InfoTooltip';
import { CompanyRole } from '../../../types/company/CompanyRole';

const warningMessage = "At least, one API Access Token must be selected.";

interface Props {
    token?: AccessToken;
    onClose: () => void;
}

export function AddEditTokenPopup({ onClose, token }: Props) {
    const dispatch = useDispatch();
    const companyRequestState = useSelector((state: AppState) => state.manageToken.companyRequestState);
    const generatedToken = useSelector((state: AppState) => state.manageToken.generatedToken);
    const generateRequestStatus = useSelector((state: AppState) => state.manageToken.generateRequestStatus);
    const editRequestStatus = useSelector((state: AppState) => state.manageToken.editRequestStatus);
    const companies = useSelector((state: AppState) => state.manageToken.companies);

    const initialValues = {
        id: token?.id,
        apis: token?.apis || [],
        companyId: token?.company?.id,
    };

    const isEdit = !!token?.id;

    const generateButtonVisible =
        generateRequestStatus === RequestState.none ||
        generateRequestStatus === RequestState.request

    useEffect(() => {
        dispatch(manageTokenActions.requestCompanies());

        return () => {
            dispatch(manageTokenActions.setUpdateTokenRequestStatus(RequestState.none));
            dispatch(manageTokenActions.setGenerateRequestStatus(RequestState.none));
            dispatch(manageTokenActions.storeGeneratedToken());
        }
    }, [dispatch]);

    useEffect(() => {
        if (isRequestSuccess(editRequestStatus)) {
            // Auto-close popup when update done
            onClose();
        }
        // eslint-disable-next-line
    }, [dispatch, editRequestStatus])

    const handleSubmit = ({ apis, companyId, id }: {
        id: number | undefined,
        companyId: number | undefined,
        apis: AccessTokenApi[]
    }) => {
        if (isEdit && id && companyId) {
            dispatch(manageTokenActions.update(id, companyId, apis));
        } else if (companyId) {
            dispatch(manageTokenActions.generate(companyId, apis))
        }
    };

    const handleClose = () => {
        if (!isRequesting(generateRequestStatus, editRequestStatus)) {
            onClose();
        }
    }

    const hasMediaRole = (companyId?: number) => {
        return companies.some(c => c.id === companyId && c.role === CompanyRole.Media);
    }


    return (
        <Popup
            modalClass="modal-token"
            title={isEdit ? <>Edit Token <span className="name-tag">{token?.company.name}</span></> : "Generate New Token"}
            renderInBody={true}
            onClose={handleClose}
        >
            <Formik
                initialValues={initialValues}
                enableReinitialize
                validateOnBlur={false}
                validateOnChange={false}
                onSubmit={handleSubmit}
            >
                {({ setFieldValue, values }) => {
                    const isSingleApiValue = values.apis.length === 1;

                    const isMedia = hasMediaRole(values.companyId);
                    const withBlotterApiReader = values.apis.includes(AccessTokenApi.BlotterApiReader);
                    const withBwicMonitorApiReader = values.apis.includes(AccessTokenApi.BwicMonitorApiReader);
                    const withBwicMonitorApiHistoryReader = values.apis.includes(AccessTokenApi.BwicMonitorApiHistoryReader);
                    const withDashboardApiUser = values.apis.includes(AccessTokenApi.DashboardApiReader);
                    const withEvalApiReader = values.apis.includes(AccessTokenApi.EvalApiReader);
                    const withIssuanceMonitorApiReader = values.apis.includes(AccessTokenApi.IssuanceMonitorApiReader);
                    const withIssuanceMonitorApiHistoryReader = values.apis.includes(AccessTokenApi.IssuanceMonitorApiHistoryReader);

                    const withPortfolioApiReader = values.apis.includes(AccessTokenApi.PortfolioApiReader);
                    const withPortfolioApiWriter = values.apis.includes(AccessTokenApi.PortfolioApiWriter);

                    const handleCompanyChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
                        setFieldValue(e.target.name, Number(e.target.value))
                        const isMedia = hasMediaRole(Number(e.target.value))
                        if (isMedia) {
                            setFieldValue('apis', values.apis.filter(api => api === AccessTokenApi.DashboardApiReader))
                        }
                    }

                    return (
                        <>
                            <PopupBody>
                                {
                                    !isEdit &&
                                    <label className="form-label">Company Name<span className="text-red">*</span>
                                        <select
                                            className={'form-control form-select'}
                                            id="companyId"
                                            name="companyId"
                                            value={values.companyId || ''}
                                            required
                                            onChange={handleCompanyChange}
                                            disabled={companyRequestState !== RequestState.success}
                                        >
                                            <option value='' disabled hidden>Select Company</option>
                                            {companies.map(c => <option key={c.id} value={c.id}>{c.name}</option>)}
                                        </select>
                                    </label>}
                                <ul className="api-list">
                                    <li>
                                        <OnHoverTooltip overlay={isEdit &&
                                            isSingleApiValue &&
                                            withBlotterApiReader && warningMessage}>
                                            <Checkbox
                                                disabled={isMedia}
                                                name={AccessTokenApi.BlotterApiReader}
                                                label={AccessTokenApiLabels[AccessTokenApi.BlotterApiReader]}
                                                onChange={e => e.target.checked
                                                    ? setFieldValue("apis", [...values.apis, e.target.name])
                                                    : setFieldValue("apis", values.apis.filter(a => a !== e.target.name))
                                                }
                                                checked={withBlotterApiReader}
                                            />
                                        </OnHoverTooltip>
                                    </li>
                                    <li>
                                        <OnHoverTooltip overlay={isEdit &&
                                            isSingleApiValue &&
                                            values.apis.includes(AccessTokenApi.AllBwicMonitorApi) && warningMessage}>
                                            <Checkbox
                                                disabled={isMedia}
                                                label={AccessTokenApiLabels.AllBwicMonitorApi}
                                                onChange={e => {
                                                    e.target.checked
                                                        ? setFieldValue("apis", [...values.apis, AccessTokenApi.BwicMonitorApiReader, AccessTokenApi.BwicMonitorApiHistoryReader])
                                                        : setFieldValue("apis", values.apis.filter(a => a !== AccessTokenApi.BwicMonitorApiReader && a !== AccessTokenApi.BwicMonitorApiHistoryReader))
                                                }}
                                                checked={
                                                    withBwicMonitorApiReader && withBwicMonitorApiHistoryReader
                                                }
                                                partially={
                                                    (withBwicMonitorApiReader ||
                                                        withBwicMonitorApiHistoryReader) &&
                                                    !(withBwicMonitorApiReader &&
                                                        withBwicMonitorApiHistoryReader)
                                                }
                                            />
                                        </OnHoverTooltip>
                                        <ul>
                                            <li>
                                                <OnHoverTooltip overlay={isEdit &&
                                                    isSingleApiValue &&
                                                    withBwicMonitorApiReader && warningMessage}>
                                                    <Checkbox
                                                        disabled={isMedia}
                                                        name={AccessTokenApi.BwicMonitorApiReader}
                                                        label={AccessTokenApiLabels[AccessTokenApi.BwicMonitorApiReader]}
                                                        onChange={e => e.target.checked
                                                            ? setFieldValue("apis", [...values.apis, e.target.name])
                                                            : setFieldValue("apis", values.apis.filter(a => a !== e.target.name))
                                                        }
                                                        checked={withBwicMonitorApiReader}
                                                    />
                                                </OnHoverTooltip>
                                                <InfoTooltip text="Getting data about Bidding and Scheduled BWICs" disabled={isMedia} />
                                            </li>
                                            <li>
                                                <OnHoverTooltip overlay={isEdit &&
                                                    isSingleApiValue &&
                                                    withBwicMonitorApiHistoryReader && warningMessage}>
                                                    <Checkbox
                                                        disabled={isMedia}
                                                        name={AccessTokenApi.BwicMonitorApiHistoryReader}
                                                        label={AccessTokenApiLabels[AccessTokenApi.BwicMonitorApiHistoryReader]}
                                                        onChange={e => e.target.checked
                                                            ? setFieldValue("apis", [...values.apis, e.target.name])
                                                            : setFieldValue("apis", values.apis.filter(a => a !== e.target.name))
                                                        }
                                                        checked={withBwicMonitorApiHistoryReader}
                                                    />
                                                </OnHoverTooltip>
                                                <InfoTooltip text="Getting data about Canceled and Finished BWICs" disabled={isMedia} />
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <OnHoverTooltip overlay={isEdit &&
                                            isSingleApiValue &&
                                            withEvalApiReader && warningMessage}>
                                            <Checkbox
                                                disabled={isMedia}
                                                name={AccessTokenApi.EvalApiReader}
                                                label={AccessTokenApiLabels[AccessTokenApi.EvalApiReader]}
                                                onChange={e => e.target.checked
                                                    ? setFieldValue("apis", [...values.apis, e.target.name])
                                                    : setFieldValue("apis", values.apis.filter(a => a !== e.target.name))
                                                }
                                                checked={withEvalApiReader}
                                            />
                                        </OnHoverTooltip>
                                    </li>
                                    <li>
                                        <OnHoverTooltip overlay={isEdit &&
                                            isSingleApiValue &&
                                            values.apis.includes(AccessTokenApi.IssuanceMonitorApi) && warningMessage}>
                                            <Checkbox
                                                disabled={isMedia}
                                                label={AccessTokenApiLabels[AccessTokenApi.IssuanceMonitorApi]}
                                                onChange={e => {
                                                    e.target.checked
                                                        ? setFieldValue("apis", [...values.apis, AccessTokenApi.IssuanceMonitorApiReader, AccessTokenApi.IssuanceMonitorApiHistoryReader])
                                                        : setFieldValue("apis", values.apis.filter(a => a !== AccessTokenApi.IssuanceMonitorApiReader && a !== AccessTokenApi.IssuanceMonitorApiHistoryReader))
                                                }}
                                                checked={
                                                    withIssuanceMonitorApiReader && withIssuanceMonitorApiHistoryReader
                                                }
                                                partially={
                                                    (withIssuanceMonitorApiReader ||
                                                        withIssuanceMonitorApiHistoryReader) &&
                                                    !(withIssuanceMonitorApiReader &&
                                                        withIssuanceMonitorApiHistoryReader)
                                                }
                                            />
                                        </OnHoverTooltip>
                                        <ul>
                                            <li>
                                                <OnHoverTooltip overlay={isEdit &&
                                                    isSingleApiValue &&
                                                    withIssuanceMonitorApiReader && warningMessage}>
                                                    <Checkbox
                                                        disabled={isMedia}
                                                        name={AccessTokenApi.IssuanceMonitorApiReader}
                                                        label={AccessTokenApiLabels[AccessTokenApi.IssuanceMonitorApiReader]}
                                                        onChange={e => e.target.checked
                                                            ? setFieldValue("apis", [...values.apis, e.target.name])
                                                            : setFieldValue("apis", values.apis.filter(a => a !== e.target.name))
                                                        }
                                                        checked={withIssuanceMonitorApiReader}
                                                    />
                                                </OnHoverTooltip>
                                                <InfoTooltip text="Upcoming: ‘Getting data about Active, Priced, On Hold transactions" disabled={isMedia} />
                                            </li>
                                            <li>
                                                <OnHoverTooltip overlay={isEdit &&
                                                    isSingleApiValue &&
                                                    withIssuanceMonitorApiHistoryReader && warningMessage}>
                                                    <Checkbox
                                                        disabled={isMedia}
                                                        name={AccessTokenApi.IssuanceMonitorApiHistoryReader}
                                                        label={AccessTokenApiLabels[AccessTokenApi.IssuanceMonitorApiHistoryReader]}
                                                        onChange={e => e.target.checked
                                                            ? setFieldValue("apis", [...values.apis, e.target.name])
                                                            : setFieldValue("apis", values.apis.filter(a => a !== e.target.name))
                                                        }
                                                        checked={withIssuanceMonitorApiHistoryReader}
                                                    />
                                                </OnHoverTooltip>
                                                <InfoTooltip text="Getting data about Closed transactions" disabled={isMedia} />
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <OnHoverTooltip overlay={isEdit &&
                                            isSingleApiValue &&
                                            withDashboardApiUser && warningMessage}>
                                            <Checkbox
                                                name={AccessTokenApi.DashboardApiReader}
                                                label={AccessTokenApiLabels[AccessTokenApi.DashboardApiReader]}
                                                onChange={e => e.target.checked
                                                    ? setFieldValue("apis", [...values.apis, e.target.name])
                                                    : setFieldValue("apis", values.apis.filter(a => a !== e.target.name))
                                                }
                                                checked={withDashboardApiUser}
                                            />
                                        </OnHoverTooltip>
                                    </li>
                                    <li>
                                        <OnHoverTooltip overlay={isEdit &&
                                            isSingleApiValue &&
                                            values.apis.includes(AccessTokenApi.PortfolioApiReaderWriter) && warningMessage}>
                                            <Checkbox
                                                disabled={isMedia}
                                                label={AccessTokenApiLabels[AccessTokenApi.PortfolioApiReaderWriter]}
                                                onChange={e => {
                                                    e.target.checked
                                                        ? setFieldValue("apis", [...values.apis, AccessTokenApi.PortfolioApiReader, AccessTokenApi.PortfolioApiWriter])
                                                        : setFieldValue("apis", values.apis.filter(a => ![AccessTokenApi.PortfolioApiReader, AccessTokenApi.PortfolioApiWriter].includes(a)))
                                                }}
                                                checked={
                                                    withPortfolioApiReader && withPortfolioApiWriter
                                                }
                                                partially={
                                                    (withPortfolioApiReader || withPortfolioApiWriter) &&
                                                    !(withPortfolioApiReader && withPortfolioApiWriter)
                                                }
                                            />
                                        </OnHoverTooltip>
                                        <ul>
                                            <li>
                                                <OnHoverTooltip overlay={isEdit &&
                                                    isSingleApiValue &&
                                                    withPortfolioApiReader && warningMessage}>
                                                    <Checkbox
                                                        disabled={isMedia}
                                                        name={AccessTokenApi.PortfolioApiReader}
                                                        label={AccessTokenApiLabels[AccessTokenApi.PortfolioApiReader]}
                                                        onChange={e => e.target.checked
                                                            ? setFieldValue("apis", [...values.apis, e.target.name])
                                                            : setFieldValue("apis", values.apis.filter(a => a !== e.target.name))
                                                        }
                                                        checked={withPortfolioApiReader}
                                                    />
                                                </OnHoverTooltip>
                                            </li>
                                            <li>
                                                <OnHoverTooltip overlay={isEdit &&
                                                    isSingleApiValue &&
                                                    withPortfolioApiWriter && warningMessage}>
                                                    <Checkbox
                                                        disabled={isMedia}
                                                        name={AccessTokenApi.PortfolioApiWriter}
                                                        label={AccessTokenApiLabels[AccessTokenApi.PortfolioApiWriter]}
                                                        onChange={e => e.target.checked
                                                            ? setFieldValue("apis", [...values.apis, e.target.name])
                                                            : setFieldValue("apis", values.apis.filter(a => a !== e.target.name))
                                                        }
                                                        checked={withPortfolioApiWriter}
                                                    />
                                                </OnHoverTooltip>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                                {!isEdit &&
                                    <StatusMessageSection type={StatusMessageSectionType.Info}>
                                        {
                                            generatedToken
                                                ? <>
                                                    <p>Token was generated. You will have only one chance to copy it.</p>
                                                    <p><TokenView token={generatedToken?.token} /></p>
                                                </>
                                                : <p> Once token will be generated, you will have only one chance to copy it.</p>
                                        }
                                    </StatusMessageSection>
                                }
                            </PopupBody>
                            <PopupFooter>
                                <div className="copy-to">
                                    {generatedToken &&
                                        <CopyToClipboard duration={3000} stateDescription={true} text={generatedToken?.token} />
                                    }
                                </div>
                                <div className="btns">
                                    <button
                                        disabled={isRequesting(generateRequestStatus, editRequestStatus)}
                                        className="btn btn-ghost"
                                        onClick={onClose}
                                    >
                                        {generatedToken && !isEdit ? "close" : "cancel"}
                                    </button>
                                    {
                                        isEdit
                                            ? <button
                                                disabled={isRequesting(editRequestStatus) || values.apis.length === 0}
                                                onClick={() => handleSubmit(values)}
                                                className="btn btn-main flex-none">
                                                save
                                            </button>
                                            : generateButtonVisible && <button
                                                className="btn btn-main flex-none"
                                                disabled={
                                                    generateRequestStatus === RequestState.request ||
                                                    !values.companyId ||
                                                    values.apis.length === 0
                                                }
                                                onClick={() => handleSubmit(values)}
                                            >
                                                generate
                                            </button>
                                    }
                                </div>
                            </PopupFooter>
                        </>
                    )
                }}
            </Formik>
        </Popup>
    );
}

interface TokenViewProps {
    token: string;
}

const maskedToken = '****************************************';

function TokenView({ token }: TokenViewProps) {
    const [isMaskedTokenVisible, setIsMaskedTokenVisible] = useState(true);

    return (
        <>
            Generated token:<br />
            <div className="flex-row justify-content-space-between align-items-flex-start ">
                <span className="text-medium">{isMaskedTokenVisible ? maskedToken : token}</span>
                <button className="btn-link" onClick={() => setIsMaskedTokenVisible(prev => !prev)}>
                    {
                        isMaskedTokenVisible
                            ? <IconSVG name="view" width={16} height={16} />
                            : <IconSVG name="hidden" width={16} height={16} />
                    }
                </button>
            </div>
        </>
    );
}


import { useDispatch, useSelector } from 'react-redux';
import { isEqual } from 'lodash';
import { FilterButton, FilterClear, FilterPanel, FilterSection } from '../../filters';
import { FilterSelect } from '../../common/filters/FilterSelect';
import { AppState } from '../../../types/state/AppState';
import { ApiTokenStatus, AccessTokenStatusLabels, AccessTokenApi, AccessTokenApiLabels } from '../../../types/management/AccessToken';
import { manageTokenActions } from '../../../actions/manage-tokens.actions';
import { bwicDateFilterOptions } from '../../../constants';
import { DateRangeSelector } from '../../common';
import { DateFilterOption } from '../../../types/filters/DateFilterOption';
import { DateRange } from '../../../types/filters/DateRange';
import { arrayUtils } from '../../../utils';
import { useLocation } from 'react-router';
import { useEffect, useMemo } from 'react';

export const TokensFilter = ({ enabled }: { enabled: boolean }) => {
    const { search } = useLocation();
    const queryParams = useMemo(() => new URLSearchParams(search), [search]);
    const companyIdParam = queryParams.get('companyId');
    const dispatch = useDispatch();
    const tokens = useSelector((s: AppState) => s.manageToken.tokens);
    const filter = useSelector((s: AppState) => s.manageToken.filter);
    const initialFilter = useSelector((s: AppState) => s.manageToken.initialFilter);
    const lastAppliedFilter = useSelector((s: AppState) => s.manageToken.lastAppliedTokenFilter);

    const filtersChanged = !isEqual({ ...filter, searchTerm: '' }, { ...lastAppliedFilter, searchTerm: '' });
    const isClearFilterShown = enabled
        && !isEqual({ ...initialFilter }, { ...filter, searchTerm: '' });
    const resetFilters = () => dispatch(manageTokenActions.resetTokenFilters());

    const companyList = arrayUtils.distinct(tokens, t => t.company.id)

    useEffect(() => {
        if (companyIdParam) {
            dispatch(manageTokenActions.toogleFilterCompanies([Number(companyIdParam)]))
            dispatch(manageTokenActions.applyTokenFilters())
        }
    }, [companyIdParam, dispatch])

    return (
        <div className="filters-area flex-row align-items-flex-end">
            <FilterPanel>
                <FilterSection>
                    <FilterSelect
                        title="Company"
                        multiply={true}
                        withSearch={true}
                        disabled={!enabled}
                        isApplied={lastAppliedFilter.companies.length !== 0}
                        options={companyList.map(x => ({
                            text: x.company.name,
                            value: x.company.id,
                            selected: filter.companies.some(id => id === x.company.id),
                            visible: true,
                            disabled: false
                        }))}
                        onChangeItemSelection={item => dispatch(manageTokenActions.toogleFilterCompanies([item.value]))}
                        onClearAll={() => dispatch(manageTokenActions.setFilterCompanies([]))}
                        onSelectAll={() => dispatch(manageTokenActions.setFilterCompanies(companyList.map(t => t.company.id)))}
                    />
                    <FilterSelect
                        title="Token Status"
                        multiply={true}
                        disabled={!enabled}
                        isApplied={
                            filter.active === lastAppliedFilter.active &&
                            filter.expired === lastAppliedFilter.expired &&
                            filter.revoked === lastAppliedFilter.revoked
                        }
                        options={[
                            ApiTokenStatus.Active,
                            ApiTokenStatus.Expired,
                            ApiTokenStatus.Revoked
                        ].map((x: ApiTokenStatus) => ({
                            text: AccessTokenStatusLabels[x],
                            value: x,
                            selected: (
                                (filter.active && x === ApiTokenStatus.Active) ||
                                (filter.expired && x === ApiTokenStatus.Expired) ||
                                (filter.revoked && x === ApiTokenStatus.Revoked)
                            ),
                            visible: true,
                            disabled: false
                        }))}
                        onChangeItemSelection={(item => {
                            (item.value === ApiTokenStatus.Active && dispatch(manageTokenActions.toogleFilterStatusActive()));
                            (item.value === ApiTokenStatus.Expired && dispatch(manageTokenActions.toogleFilterStatusExpired()));
                            (item.value === ApiTokenStatus.Revoked && dispatch(manageTokenActions.toogleFilterStatusRevoked()));
                        })}
                        onClearAll={() => {
                            filter.active && dispatch(manageTokenActions.toogleFilterStatusActive());
                            filter.expired && dispatch(manageTokenActions.toogleFilterStatusExpired());
                            filter.revoked && dispatch(manageTokenActions.toogleFilterStatusRevoked());
                        }}
                        onSelectAll={() => {
                            !filter.active && dispatch(manageTokenActions.toogleFilterStatusActive());
                            !filter.expired && dispatch(manageTokenActions.toogleFilterStatusExpired());
                            !filter.revoked && dispatch(manageTokenActions.toogleFilterStatusRevoked());
                        }}
                    />
                    <FilterSelect
                        title="API"
                        multiply={true}
                        disabled={!enabled}
                        isApplied={
                            filter.blotterApiReader === lastAppliedFilter.blotterApiReader &&
                            filter.bwicMonitorApiReader === lastAppliedFilter.bwicMonitorApiReader &&
                            filter.issuanceMonitorApiReader === lastAppliedFilter.issuanceMonitorApiReader &&
                            filter.portfolioApiReaderWriter === lastAppliedFilter.portfolioApiReaderWriter &&
                            filter.evalApiReader === lastAppliedFilter.evalApiReader &&
                            filter.dashboardApiReader === lastAppliedFilter.dashboardApiReader
                        }
                        options={[
                            AccessTokenApi.BlotterApiReader,
                            AccessTokenApi.AllBwicMonitorApi,
                            AccessTokenApi.EvalApiReader,
                            AccessTokenApi.IssuanceMonitorApi,
                            AccessTokenApi.DashboardApiReader,
                            AccessTokenApi.PortfolioApiReaderWriter,
                        ].map(x => ({
                            text: AccessTokenApiLabels[x],
                            value: x,
                            selected: (
                                (filter.blotterApiReader && x === AccessTokenApi.BlotterApiReader) ||
                                (filter.bwicMonitorApiReader && x === AccessTokenApi.AllBwicMonitorApi) ||
                                (filter.evalApiReader && x === AccessTokenApi.EvalApiReader)||
                                (filter.issuanceMonitorApiReader && x === AccessTokenApi.IssuanceMonitorApiReader) ||
                                (filter.dashboardApiReader && x === AccessTokenApi.DashboardApiReader) ||
                                (filter.portfolioApiReaderWriter && x === AccessTokenApi.PortfolioApiReaderWriter)
                            ),
                            visible: true,
                            disabled: false
                        }))
                        }
                        onChangeItemSelection={item => {
                            (item.value === AccessTokenApi.BlotterApiReader && dispatch(manageTokenActions.toogleFilterApiBlotterApiReader()));
                            (item.value === AccessTokenApi.AllBwicMonitorApi && dispatch(manageTokenActions.toogleFilterApiBwicMonitorApiReader()));
                            (item.value === AccessTokenApi.IssuanceMonitorApi && dispatch(manageTokenActions.toogleFilterApiIssuanceMonitorApiReader()));
                            (item.value === AccessTokenApi.PortfolioApiReaderWriter && dispatch(manageTokenActions.toogleFilterApiPortfolioApi()));
                            (item.value === AccessTokenApi.EvalApiReader && dispatch(manageTokenActions.toogleFilterApiEvalApiReader()));
                            (item.value === AccessTokenApi.DashboardApiReader && dispatch(manageTokenActions.toogleFilterApiDashboardApiReader()));
                        }}
                        onClearAll={() => {
                            filter.blotterApiReader && dispatch(manageTokenActions.toogleFilterApiBlotterApiReader());
                            filter.bwicMonitorApiReader && dispatch(manageTokenActions.toogleFilterApiBwicMonitorApiReader());
                            filter.issuanceMonitorApiReader && dispatch(manageTokenActions.toogleFilterApiIssuanceMonitorApiReader());
                            filter.portfolioApiReaderWriter && dispatch(manageTokenActions.toogleFilterApiPortfolioApi());
                            filter.evalApiReader && dispatch(manageTokenActions.toogleFilterApiEvalApiReader());
                            filter.dashboardApiReader && dispatch(manageTokenActions.toogleFilterApiDashboardApiReader());
                        }}
                        onSelectAll={() => {
                            !filter.blotterApiReader && dispatch(manageTokenActions.toogleFilterApiBlotterApiReader());
                            !filter.bwicMonitorApiReader && dispatch(manageTokenActions.toogleFilterApiBwicMonitorApiReader());
                            !filter.issuanceMonitorApiReader && dispatch(manageTokenActions.toogleFilterApiIssuanceMonitorApiReader());
                            !filter.portfolioApiReaderWriter && dispatch(manageTokenActions.toogleFilterApiPortfolioApi());
                            !filter.evalApiReader && dispatch(manageTokenActions.toogleFilterApiEvalApiReader());
                            !filter.dashboardApiReader && dispatch(manageTokenActions.toogleFilterApiDashboardApiReader());
                        }}
                    />
                    <DateRangeSelector
                        restyled={true}
                        title="Gen. Date"
                        isApplied={lastAppliedFilter.selectedDateOption.key !== bwicDateFilterOptions.unspecified.key}
                        onReset={() => dispatch(manageTokenActions.setDateFilterChange(bwicDateFilterOptions.unspecified))}
                        disabled={!enabled}
                        selectedDateOption={filter.selectedDateOption}
                        customDateRange={filter.customDateRange}
                        onSelectedDateChange={(dateOption: DateFilterOption) => dispatch(manageTokenActions.setDateFilterChange(dateOption))}
                        onCustomDateChange={(dateRange: DateRange) => dispatch(manageTokenActions.setDateFilterRangeChange(dateRange))}
                        acceptedOptions={[
                            bwicDateFilterOptions.unspecified,
                            bwicDateFilterOptions.thisWeek,
                            bwicDateFilterOptions.lastWeek,
                            bwicDateFilterOptions.lastMonth,
                            bwicDateFilterOptions.lastYear,
                            bwicDateFilterOptions.custom
                        ]}
                        disabledDays={{ after: new Date() }}
                    />
                    <FilterButton
                        caption="Apply"
                        disabled={!filtersChanged}
                        onClick={() => dispatch(manageTokenActions.applyTokenFilters())}
                    />
                    <FilterClear isShown={isClearFilterShown} onClick={resetFilters} />
                </FilterSection>
            </FilterPanel>
        </div>
    );
}


import moment from "moment";
import { useEffect } from "react";
import { bwicDateFilterOptions } from "../../../constants";
import { ApiTokenStatus, AccessTokenApi, AccessToken } from "../../../types/management/AccessToken";
import { ManageTokenFilterState } from "../../../types/state/ManageTokenState";
import { getSearchDateRange } from "../../../utils";

export function useTokenFilter(tokens: AccessToken[], filter: ManageTokenFilterState, searchTerm: string, setFilter: Function) {
    useEffect(() => {
        let filteringResult = tokens;

        const statuses = [
            filter.active && ApiTokenStatus.Active,
            filter.expired && ApiTokenStatus.Expired,
            filter.revoked && ApiTokenStatus.Revoked,
        ].filter(status => status);

        const apisArray = [
            filter.blotterApiReader && AccessTokenApi.BlotterApiReader,
            filter.bwicMonitorApiReader && AccessTokenApi.BwicMonitorApiReader,
            filter.bwicMonitorApiHistoryReader && AccessTokenApi.BwicMonitorApiHistoryReader,
            filter.evalApiReader && AccessTokenApi.EvalApiReader,
            filter.issuanceMonitorApiReader && AccessTokenApi.IssuanceMonitorApiReader,
            filter.issuanceMonitorApiHistoryReader && AccessTokenApi.IssuanceMonitorApiHistoryReader,
            ...(filter.portfolioApiReaderWriter ? [AccessTokenApi.PortfolioApiReader, AccessTokenApi.PortfolioApiWriter]: []),
            filter.dashboardApiReader && AccessTokenApi.DashboardApiReader,
        ].filter(status => status);

        if (statuses.length) {
            filteringResult = filteringResult.filter(t => statuses.some(s => s === t.status));
        }

        if (apisArray.length) {
            filteringResult = filteringResult.filter(t =>
                apisArray.some(a => t.apis.some(api => api === a)));
        }

        if (filter.companies?.length) {
            filteringResult = filteringResult.filter(t => filter.companies.some(id => t.company.id === id));
        }

        if (searchTerm) {
            filteringResult = filteringResult.filter(t =>
                t?.company?.name.toLowerCase().includes(searchTerm.toLowerCase()))
        }

        if (filter.customDateRange
            && filter.customDateRange.from
            && filter.customDateRange.to
            && filter.selectedDateOption.key === bwicDateFilterOptions.custom.key) {
            filteringResult = filterTokensByRange(filter.customDateRange.from, filter.customDateRange.to as Date, filteringResult);
        }

        if (filter.selectedDateOption
            && filter.selectedDateOption.key !== bwicDateFilterOptions.unspecified.key
            && filter.selectedDateOption.key !== bwicDateFilterOptions.custom.key) {
            const dateFilter = getSearchDateRange(filter);
            filteringResult = filterTokensByRange(dateFilter.dateFrom as string, dateFilter.dateTo as string, filteringResult);

        }
        setFilter(filteringResult)
    }, [tokens, filter, searchTerm, setFilter])
}

function filterTokensByRange(from: Date | string, to: Date | string, tokens: AccessToken[]) {
    return tokens.filter((token: AccessToken) => {
        const creationDate = moment(token.creationDate);
        return creationDate.isSameOrAfter(from, 'day') && creationDate.isSameOrBefore(to, 'day')
    });
}
